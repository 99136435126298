
 export const environment = {
    appVersion : '1.0.5',
    baseUrl  :'https://api.prj.weblineapps.com/',
    linuxDownloadUrl : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/worktualizestage_1.0.4_amd64.deb',
    macDownloadUrl : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/WorktualizeStage-1.0.4.pkg',
    windowsDownloadUrl : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/WorktualizeStage-1.0.4.exe',
    macDownloadApplicationURL :'https://play.google.com/store/apps/details?id=com.wli.wlitracker&pcampaignid=web_share',
    androidDownloadApplicationURL :'https://play.google.com/store/apps/details?id=com.wli.wlitracker&pcampaignid=web_share',
    userImportSampleTemplateURL : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/template/SampleUser.xlsx',
    sampleMasterPatternTemplateURL: 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/template/SampleMasterPattern.csv',
    sampleHolidaysTemplateURL: 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/template/SampleHolidayTemplate.csv',
    sampleLeaveTemplateURL: 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/template/SampleLeaveTemplate.xlsx',
    samplePayrollTemplateURL: 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/template/SamplePayrollTemplate.xlsx',
    sampleLOPAfterLeaveBookedTemplateURL : 'https://s3.ap-south-1.amazonaws.com/stage-prj.weblineapps.com/download/template/SampleLOPTemplate.xlsx',
    production: false,
  };

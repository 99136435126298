
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbInputModule,
  NbMenuModule,
  NbSidebarModule,
  NbTabsetModule,
  NbTimepickerModule,
  NbToastrModule,
  NbWindowModule,

} from '@nebular/theme';
import { NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { environment } from '../environments/environment';
import { APP_BASE_HREF } from '@angular/common';
import { TokenInterceptor } from './token.interceptor';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ngx-lightbox';
import { ExportAsModule } from 'ngx-export-as';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { authInterceptorProviders } from './auth/auth.interceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
@NgModule({
  declarations: [AppComponent],
  imports: [
  BrowserModule,
    BrowserAnimationsModule,
    NgMultiSelectDropDownModule.forRoot(),
    HttpClientModule,
    NbTabsetModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot({
      duration : 4000
    }),
    NgxDaterangepickerMd.forRoot(),
    NbTimepickerModule.forRoot(),
    LightboxModule,
    ExportAsModule,
    NgxMaterialTimepickerModule,
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: 'email',

          baseEndpoint: environment.baseUrl,
           login: {
             endpoint: 'login'
           },
        }),
      ],
      forms: {},
    }),
    NgbModule,
    NbInputModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    NgbActiveModal,
    Title,
    authInterceptorProviders,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
})
export class AppModule {
}

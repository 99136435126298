import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  ptoken: any;
  baseUrl:any;
  orgId:any;

  constructor(private injector: Injector) { 
    this.baseUrl = environment.baseUrl
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 
    const accessToken = sessionStorage.getItem('temp_access_token') ? JSON.parse(sessionStorage.getItem('temp_access_token')) : JSON.parse(sessionStorage.getItem('access_token'));
    this.ptoken = accessToken ? accessToken : JSON.parse(localStorage.getItem('access_token'));
    const organizationId = sessionStorage.getItem('temp_orgId') ? JSON.parse(sessionStorage.getItem('temp_orgId')) : JSON.parse(sessionStorage.getItem('orgId'));
    this.orgId = organizationId ? organizationId : JSON.parse(localStorage.getItem('orgId'));

    if (request.url == this.baseUrl+"oauth/token") {
      request = request.clone({
        setHeaders: {
          Authorization: `Basic Y2xpZW50OnNlY3JldA==`,
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
          'X_HEADER_ORG_ID': `${this.orgId}`
        }
      });
    }else if (request.url == this.baseUrl+"login" || request.url.includes('forgetPassword')){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.ptoken}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
        }
      });
    }else if (request.url == this.baseUrl+"bug/uploadAttachments" || request.url == this.baseUrl+"holiday/import" || request.url == this.baseUrl+"importLOPAfterLeaveBooked/upload" || request.url == this.baseUrl+"importLeaveBook/upload" || request.url == this.baseUrl+"leaves/import" || request.url == this.baseUrl+"upload" || request.url == this.baseUrl+"taskManagement/uploadAttachment" || request.url == this.baseUrl+"restricted-logs/upload-csv"){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.ptoken}`,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
          'X_HEADER_ORG_ID': `${this.orgId}`
        }
      });
    }else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.ptoken}`,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization, Content-Length, X-Requested-With',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
          'X_HEADER_ORG_ID': `${this.orgId}`
        }
      });
    }

    return next.handle(request);
  }
}
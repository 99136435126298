import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { BehaviorSubject, interval, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { DashboardService } from '../../../pages/dashboard/dashboard.service';
import { map, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { toastrTitle } from '../../../constant/admin-constant';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy  {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  isLightSelected : boolean = false;
  user: any;
  userId: any;
  userDetails: any = [];
  utoken: any = {};
  username: any;
  id: any;
  feed: any[] = [];
  public rows = [];
  timeLeft: number;
  public intervalTimer = interval(300000);
  isTeamLead :any ;
  isAdmin : any;
  private subscription;
  themeResponse = [];
  loading = false;

  themes = [
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'default',
      name: 'Light',
    },
  ];

  currentTheme :any ;
  token : any;
  userMenu = [];
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private _router: Router,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private toastr: NbToastrService,
    private breakpointService: NbMediaBreakpointsService) {
    //this.themeService.changeTheme('dark');

    this.userDetails = JSON.parse(sessionStorage.getItem('user_details'));
    this.utoken = sessionStorage.getItem('access_token');

    this.currentTheme = this.themeService.currentTheme;
    this.isTeamLead = this.userDetails.teamLead;
    this.isAdmin = this.userDetails.roles[0] == 'ROLE_ADMIN';
    if (this.utoken == null) {
      this._router.navigateByUrl('auth/login');
      this.userMenu = [
        { title: 'Login', link: '/auth/login' }
      ];
    }
    else {
      this.menuService.navigateHome();
      this.userMenu = [
        { title: 'View Profile', link: '/pages/user-profile' },
        { title: 'Change Password', link: '/pages/change-password' },
        { title: 'Log out', link: '/auth/logout' }
      ];
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    let storedTheme = sessionStorage.getItem('theme');
    if (storedTheme && storedTheme === '1') {
      this.changeTheme('default');
    }else{
      this.changeTheme('dark');
    }
      this.timeLeft =  JSON.parse(sessionStorage.getItem('tokenExpire')) - 1800000;
      if (typeof this.timeLeft  == 'undefined' && this.timeLeft  == null){
        this.timeLeft = 86400000 - 1800000;
      }
        this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.userService.getUsers()
      .subscribe((_users: any) => this.user =  this.userDetails);
    this.startTimer(); 
    this.userId = this.userDetails.userId; 
  }

  // ngOnDestroy() {
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }

  // changeTheme(themeName: string) {
  //   this.themeService.changeTheme(themeName);
  // }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
  // changeTheme(themeName: string) {
  //   this.themeService.changeTheme(themeName);
  //   if(themeName == 'default'){
  //     this.isLightSelected = true;
  //   }else{
  //     this.isLightSelected = false;
  //   }
  // }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
    this.isLightSelected = themeName === 'default';
    this.setTheme(themeName === 'default' ? 1 : 0); 
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  startTimer() {
    this.subscription = this.intervalTimer.subscribe(() => {
      if (this.timeLeft > 0) {    
        this.timeLeft -= 300000;
      } else {
        this.token = this.authService.getRefreshToken();
        if (this.token)
          this.authService.refreshToken(this.token).subscribe(() => {
            this.authService.saveToken(this.token.body.accessToken);
            this.refreshTokenSubject.next(this.token.body.accessToken);
            sessionStorage.setItem("access_token", JSON.stringify(this.token.body.accessToken));
            sessionStorage.setItem("refresh_token", JSON.stringify(this.token.body.refreshToken));
            this.timeLeft =  JSON.parse(sessionStorage.getItem('tokenExpire')) - 1800000;
            if (typeof this.timeLeft  == 'undefined' && this.timeLeft  == null){
              this.timeLeft = 86400000 - 1800000;
            }
          });
      }
    })
  }

  onAction() {
    this._router.navigate(['/pages/dashboard'])
  }

   openNav() {
    this.getFeedData();
    document.getElementById("mySidebar").style.width = "300px";
  }
  closeNav() {
    document.getElementById("mySidebar").style.width = "0";
  }
  getFeedData() {
    this.id = this.userDetails.userId;
    this.dashboardService.getIdleFeed(this.id).subscribe(
      (resp: any) => {
        if (resp.status == 200) {
          this.feed = resp.body;
          this.rows = resp.body.data;
        }
      }
    );
  }

  setTheme(theme: number) {
    let request = {
      theme: theme, 
      id: this.userId,
    };
    this.dashboardService.updateTheme(request).subscribe(
      (resp: any) => {
        if (resp.status == 200) {
          this.themeResponse = resp.body.data;
          sessionStorage.setItem('theme',theme.toString());
        }
      },
      (err: HttpErrorResponse) => {
        if (err.status == 401) {
          setTimeout(() => {
            this.toastr.danger(err.error.message, toastrTitle.error_title);
            this.loading = false;
          });
        } else {
          this.loading = false;
          this.toastr.danger(err.error.message, toastrTitle.error_title);
        }
      }
    );
  }
}

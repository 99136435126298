<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()" *ngIf="!isLightSelected">
      <img src="../../../assets/images/Worktualize-logo-white.png" alt="Worktualize Logo" class="img-fluid" (click)="onAction()">
    </a>
    <a class="logo" href="#" (click)="navigateHome()" *ngIf="isLightSelected">
      <img src="../../../assets/images/worktualize-logo.png" alt="Worktualize Logo" class="img-fluid" (click)="onAction()">
    </a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="mat-ripple user-action ng-star-inserted" *nbIsGranted="['view', 'user']" >
      <nb-user class="size-medium shape-round context-menu-host ng-star-inserted" [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]=" user?.firstName != null && user?.lastName != null ? user?.firstName + ' ' + user?.lastName : user?.username">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<nb-card id="mySidebar" class="sidebar">
  <nb-card-header class="closebtn" (click)="closeNav()">
    <nb-icon icon="close" status="danger"></nb-icon>
  </nb-card-header>
  <nb-card-body>
  <a  *ngFor="let row of rows">{{row}}</a>
  </nb-card-body>
</nb-card>

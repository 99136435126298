import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  /**
   * Function -- getTimeSheetDetails()
   * Use -- Get timesheet data on Dashboard
   * @param postData
   * @returns 
   */
  getTimeSheetDetails(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/timesheet', postData, { observe: 'response' });
  }
/**
   * Function -- getTimeSheetDetails()
   * Use -- Get timesheet data on Dashboard for filter
   * @param id 
   * @returns 
   */
  getTimeSheetDetailsFilter(id,filter) {
    return this.http.post(environment.baseUrl + 'dashboard/timesheet?Filter='+filter, id, { observe: 'response' });
  }

  /**
     * Function -- getScreenShotGroupByProject()
     * Use -- Get Screenshots base on group by project
     * @param id 
     * @returns 
     */
  getScreenShotGroupByProject(id) {
    return this.http.post(environment.baseUrl + 'dashboard/getScreenShotGroupByProject', id, { observe: 'response' });
  }

  /**
   * Function -- getScreenShot()
   * Use -- Get Screenshots on Dashboard
   * @param id 
   * @returns 
   */
  getScreenShot(id) {
    return this.http.post(environment.baseUrl + 'dashboard/screenshot', id, { observe: 'response' });
  }

  /**
   * Function -- getStatisticsData()
   * Use -- Get Statistics on Dashboard
   * @param id 
   * @returns 
   */
  getStatisticsData(id) {
    return this.http.post(environment.baseUrl + 'dashboard/activity', id, { observe: 'response' })
  }

  getReportData(id) {
    return this.http.post(environment.baseUrl + 'dashboard/activity', id, { observe: "response" })
  }

  getReportTotalTime(id){
    return this.http.post(environment.baseUrl + 'dashboard/reportTotalTime', id, { observe: 'response' })
  }
  /**
   * Function : getProjectList
   * Use -- Get Project Lists
   * @param postData 
   * @returns 
   */
  getProjectList(postData) {
    return this.http.post(environment.baseUrl + 'projects', postData, { observe: 'response' })
  }


  getPreferences(id) {
    return this.http.get(environment.baseUrl + 'dashboard/preference/' + id, { observe: 'response' });
  }

  setPreferences(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/preference', postData, { observe: 'response' });
  }

  getMemberInfo(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/getMemberInfo', postData, { observe: 'response' });
  }
  getDashboard(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/getTotalProjects', postData, { observe: 'response' });
  }
  getprojectInfo(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/getprojectInfo', postData, { observe: 'response' });
  }
  /**
   * Function : getprojectInfo1
   * Use -- Get Project Info
   * @param pageNo 
   * @param pageSize
   * @returns 
   */
  getprojectInfo1(postData) {
    return this.http.post(environment.baseUrl + "dashboard/getprojectInfo", postData, { observe: 'response' });
  }

  /**
   * Function : getprojectInfo1
   * Use -- Get Project Info
   * @param pageNo 
   * @param pageSize
   * @returns 
   */
  getMemberInfo1(postData) {
    return this.http.post(environment.baseUrl + "dashboard/getMemberInfo", postData, { observe: 'response' });
  }

  getCount(postData){
    return this.http.post(environment.baseUrl + "dashboard/getNumberOfUsersAndProjects",  postData, { observe: 'response' });
  }

  getCurrentActiveUser(id) {
    return this.http.post(environment.baseUrl + 'dashboard/getCurrentActiveMembers', id, { observe: 'response' })
  }

  getCurrentInActiveUser(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/getCurrentInActiveMembers', postData, { observe: 'response' })
  }

  /**
   * 
   * Function : zohoresenddata
   * use      : sending user data and resyncing zoho data
   * 
   */

  getResendData(postData){
    return this.http.post(environment.baseUrl + 'zohoResendLog', postData, {observe : 'response'})
  }

  /**
   * Function : getAttendanceReport
   * 
   * use     : gives check-in check-out reports of users
   */

  getAttendanceReport(id){
    return this.http.post(environment.baseUrl + 'dashboard/getAttendanceReport', id , {observe :'response'})
  }

  /**
   * Function : getAttendanceReportCount
   * 
   * use : gives a number of records or count
   */

  getAttendanceReportCount(id){
    return this.http.post(environment.baseUrl + 'dashboard/getAttendanceReportCount', id , {observe : 'response'})
  }

  getUpdateLog(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/getTaskData', postData, { observe: 'response' });
  }

  getFeeds(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getFeeds' , postData, {observe: 'response'});
  }

  getIdleFeed(id){
    return this.http.get(environment.baseUrl + 'dashboard/idleFeed/' + id, {observe :'response'});
  }

  getMissedLogFeed(id){
    return this.http.get(environment.baseUrl + 'dashboard/missedWorkHoursFeed/' + id, {observe :'response'});
  }

  getIdleExceedFeed(id){
    return this.http.get(environment.baseUrl + 'dashboard/idleExceedFeed/' + id, {observe :'response'});
  }

  getViewEdit(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/viewedit', postData, { observe: 'response' });
  }

  getViewEditFilter(id,filter) {
    return this.http.post(environment.baseUrl + 'dashboard/viewedit?Filter='+filter, id, { observe: 'response' });
  }

  getProjectWise(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getprojectdatauserwise', postData, { observe: 'response' });
  }

  getCompareReport(postData){
    return this.http.post(environment.baseUrl + 'dashboard/zohoReport', postData, { observe: 'response' });
  }

  getProjectsTotalTimeForMe(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getProjectsTotalTimeForMe', postData, { observe: 'response' });
  }

  getSummaryForMe(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getSummaryForMe', postData, { observe: 'response' });
  }

  getChartForMe(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getChartForMe', postData, { observe: 'response' });
  }

  getListOfDateFormat(){
    return this.http.get(environment.baseUrl + 'dashboard/getListOfDateFormat',  { observe: 'response' });
  }

  getListOfTimeZone(){
    return this.http.get(environment.baseUrl + 'dashboard/getListOfTimeZone',  { observe: 'response' });
  }

  getDataOfProjectUserWise(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getDataOfProjectUserWise', postData, { observe: 'response'});
  }

  getDataOfUserProjectWise(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getDataOfUserProjectWise', postData, { observe: 'response'});
  }

  getWidgetDataForScreenshot(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getscreenshotsummary', postData, { observe: 'response' });
  }

  getDefaultTaskDescription(){
    return this.http.get(environment.baseUrl + 'getOrganizationSettingByKey?key=DEFAULT_SIZE_OF_TASK_DESCRIPTION', {observe : 'response'});
  }

  getIdleExceedReport(postData){
    return this.http.post(environment.baseUrl + 'dashboard/idleExceedReport', postData, { observe: 'response'});
  }

  getMenu(){
    return this.http.get(environment.baseUrl + 'module/getModule', {observe : 'response'});
  }

  getConsolidateReport(postData){
    return this.http.post(environment.baseUrl + 'dashboard/consolidateReport', postData, { observe: 'response'});
  }

  updateTheme(postData){
    return this.http.put(environment.baseUrl + 'user/updateTheme',postData, {observe : 'response'});
  }

  deleteRole(id) {
    return this.http.post(environment.baseUrl + 'module/deleteRoleByRoleId/' + id, { observe: 'response' });
  }

  checkUserExist(id){
    return this.http.post(environment.baseUrl + 'module/checkUserRole/' + id, { observe: 'response' });
  }

  getListOfNotificationType(){
    return this.http.get(environment.baseUrl + 'dashboard/getListOfNotificationType',  { observe: 'response' });
  }

  getLeaveReport(postData){
    return this.http.post(environment.baseUrl + 'leaves/userdetails', postData, { observe: 'response'});
  }

  getPreviousLogReport(postData){
    return this.http.post(environment.baseUrl + 'dashboard/getUsersPreviousDayLogs', postData, { observe: 'response'});
  }

  updatePagination(pageSize){
    return this.http.get(environment.baseUrl + 'updatePageSize/'+pageSize, {observe : 'response'});
  }


  getCompanyWise(postData) {
    return this.http.post(environment.baseUrl + 'dashboard/companyWideTimeAndActivity', postData, { observe: 'response' });
  }
}

